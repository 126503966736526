.description-box {
  text-align: right;
  padding: 25px 20px;
}

.description-title {
  font-size: 1rem;
  font-weight: 300;
  margin: 6px;
}

@media screen and (min-width: 1050px) {
  .description-box {
    position: fixed;
    top: 0px;
    right: 0;
    text-align: right;
    padding: 50px 30px;
  }

  .description-title {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 6px;
  }
}
