.local-box {
  display: flex;
  justify-content: center;
  padding: 7vh 0px 10vh;
}

.local-content {
  text-align: center;
}

.local-item {
  margin: 2px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
}
.arrow-box {
  display: none;
}

@media screen and (min-width: 1050px) {
  .local-box {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: calc(34vw);
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
  }

  .arrow-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .local-item {
    font-size: 1.5rem;
  }
  .local-content {
    text-align: right;
    color: gray;
  }
}
