.walking-box-fixed {
  overflow: hidden;
  width: 100%;
}
.walking-box-moving {
  text-wrap: nowrap;
  white-space: nowrap;
  animation: loop 60s linear infinite;
}

.email-link {
  text-decoration: none;
  color: red;
  font-weight: 600;
}

@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-1000%);
  }
}

.walking-content {
  color: red;
  text-transform: uppercase;
  font-size: 1.5rem;
}

@media screen and (min-width: 1050px) {
  .walking-box-moving {
    animation: loop 120s linear infinite;
  }
  .walking-box-fixed {
    margin-top: 40px;
  }
}
