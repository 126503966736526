.logo-box {
  padding: 15px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1050px) {
  .logo-box {
    padding: 65px 90px 0px;
    width: 68vw;
  }
  .logo {
    width: 100%;
  }
}
