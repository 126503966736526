/* @font-face {
  font-family: "Aquawax";
  font-weight: 200;
  src: local("Aquawax-Pro-Thin-trial"),
    url(./fonts/Aquawax/Aquawax-Pro-Thin-trial.ttf) format("opentype");
} */

/* @font-face {
  font-family: "Aquawax";
  font-weight: 250;
  src: local("Aquawax-Pro-Extralight-trial"),
    url(./fonts/Aquawax/./fonts/Aquawax/Aquawax-Pro-Extralight-trial.ttf.ttf)
      format("opentype");
} */

@font-face {
  font-family: "Aquawax";
  font-weight: 300;
  src: local("Aquawax-Pro-Light-trial"),
    url(./fonts/Aquawax/Aquawax-Pro-Light-trial.ttf) format("truetype");
}

@font-face {
  font-family: "Aquawax";
  font-weight: 400;
  src: local("Aquawax-Pro-Regular-trial"),
    url(./fonts/Aquawax/Aquawax-Pro-Regular-trial.ttf) format("truetype");
}

@font-face {
  font-family: "Aquawax";
  font-weight: 600;
  src: local("Aquawax-Pro-Bold-trial"),
    url(./fonts/Aquawax/Aquawax-Pro-Bold-trial.ttf) format("opentype");
}

body {
  margin: 0;
  background-color: "rgb(255, 255, 255)";
  font-family: "Aquawax", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
