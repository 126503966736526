.video-container {
  position: relative;
  width: 100vw;
  height: 56.25vw;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56.25vw;
  animation: video-transition 0.35s cubic-bezier(0.12, 0, 0.39, 0);
  z-index: 0;
}

@media screen and (min-width: 1050px) {
  .video-container {
    position: fixed;
    width: 42vw;
    height: auto;
    top: 28%;
    left: 32%;
  }
  .overlay {
    width: 41vw;
    height: 100%;
  }
}

@keyframes video-transition {
  0% {
    opacity: 0;
    filter: brightness(100%);
    background-color: transparent;
  }
  50% {
    opacity: 0.3;
    filter: brightness(0%);
    background-color: rgba(33, 33, 33, 0.66);
  }
  100% {
    opacity: 0;
    filter: brightness(100%);
    background-color: transparent;
  }
}
